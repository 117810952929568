<template>
  <div class="upload-image">
    <label for="upload-photo" class="upload-input">
      <div v-if="isLoading"><ImageLoading /></div>
      <div v-else-if="previewImg !== null" id="previewImgWrap">
        <img
          class=""
          v-show="previewImg"
          id="previewImg"
          width="100%"
          height="auto"
          :src="previewImg"
        />
      </div>
      <div
        v-else
        class="d-flex justify-content-center align-items-center w-100"
      >
        <img class="" :src="blankImg" width="70px" height="70px" />
      </div>
    </label>
    <input
      type="file"
      name="photo"
      ref="fileInput"
      :capture="getMobileOperatingSystem() == 'iOS' ? false : true"
      :accept="accept"
      @change="fileChange"
      id="upload-photo"
    />
  </div>
</template>

<script>
import AddImage from '@/assets/add-image.svg'

import ImageLoading from '@/components/ImageLoading.vue'

export default {
  components: {
    ImageLoading,
  },
  props: {
    imageUrl: {
      type: String,
    },
    accept: {
      type: String,
      default: 'image/jpg, image/jpeg',
    },
    minWidth: {
      type: Number,
      default: 1440,
    },
    minHeight: {
      type: Number,
      default: 1920,
    },
    minSizeInBytes: {
      type: Number,
      // example 2 * 1024 * 1024 // 2 MB in bytes
    },
    maxSizeInBytes: {
      type: Number,
      // example 8 * 1024 * 1024 // 8 MB in bytes
    },
    minAlertMessage: {
      type: String,
    },
    maxAlertMessage: {
      type: String,
    },
    errorImgSizeMessage: {
      type: String,
    },
  },
  data() {
    return {
      previewImg: this.imageUrl || null,
      isLoading: false, // loading
      blankImg: AddImage,
    }
  },
  watch: {
    imageUrl(val) {
      this.previewImg = val
    },
  },
  methods: {
    async fileChange(e) {
      const { errorImgSizeMessage, minHeight, minWidth } = this
      const file = e.target.files[0]

      if (file) {
        const img = new Image()
        img.src = URL.createObjectURL(file)

        img.onload = () => {
          if (img.width > minWidth && img.height > minHeight) {
            this.checkImgSize(file)
            return
          }
          this.Swal.fire('', errorImgSizeMessage, 'warning')
        }
      }
    },
    checkImgSize(file) {
      const {
        minSizeInBytes,
        minAlertMessage,
        maxSizeInBytes,
        maxAlertMessage,
      } = this

      this.isLoading = true

      if (minSizeInBytes && file.size < minSizeInBytes) {
        this.Swal.fire('', minAlertMessage, 'warning')
        this.isLoading = false
        return
      }

      if (maxSizeInBytes && file.size > maxSizeInBytes) {
        this.Swal.fire('', maxAlertMessage, 'warning')
        this.isLoading = false
        return
      }

      this.preview(file)

      this.isLoading = false
      this.$emit('result', { file: file, fileName: file.name })
    },
    preview(file) {
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      )
      if (isSafari) {
        const img = new Image()
        img.src = URL.createObjectURL(file)
        this.previewImg = img.src
      } else {
        const fr = new FileReader()
        fr.readAsDataURL(file)
        fr.onload = (e) => {
          this.previewImg = e.target.result
        }
      }
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      }

      if (/android/i.test(userAgent)) {
        return 'Android'
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
      }

      return 'unknown'
    },
  },
}
</script>

<style lang="scss">
.upload-image {
  border-radius: 25px;
  background-size: 100%;
  width: 100%;
  height: 280px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(167, 228, 255, 0.76) 12%,
    rgba(255, 255, 255, 0.76) 100%
  );

  label {
    cursor: pointer;
    margin: 0;
  }
  #blankImg {
    border-radius: 25px;
  }
  #upload-photo {
    opacity: 0;
    display: none;
  }
  .upload-input {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #previewImgWrap {
    height: 100%;
    overflow: hidden;
  }

  #previewImg {
    padding: 10px;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
</style>
